.layout {
  background-color: hsl(0, 60%, 50%);
  animation: bgColor 20s infinite linear;
  height: 100vh;
  position: relative;
}

.dots {
  background-image: radial-gradient(rgba(255, 255, 255, 0.114) 1px, transparent 0);
  background-size: 8px 8px;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.layerThree {
  height: 500px;
  width: 500px;
  background: linear-gradient(90deg, rgba(134,58,180,0.8) 0%, rgba(29,191,253,0.8) 50%, rgba(252,155,69,0.8) 100%);;
  border-radius: 31% 69% 35% 65% / 46% 50% 50% 54% ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: spin 15s infinite linear;
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
}

.layerTwo {
  height: 500px;
  width: 500px;
  background: linear-gradient(90deg, rgba(131,58,180,0.6) 0%, rgba(253,29,29,0.6) 50%, rgba(252,176,69,0.6) 100%);
  border-radius: 76% 24% 81% 19% / 61% 25% 75% 39%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: spinBackwards 40s infinite linear;
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
}

.layerOne {
  height: 400px;
  width: 400px;
  background: linear-gradient(90deg, rgba(77,180,58,0.4) 0%, rgba(237,253,29,0.4) 50%, rgba(207,69,252,0.4) 100%);
  border-radius: 63% 37% 26% 74% / 39% 71% 29% 61% ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: spin 30s infinite linear;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
}

.card {
  height: 250px;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: calc(50% - 125px);
  left: calc(50% - 125px);
  animation: none !important;
  color: black;
  mix-blend-mode: screen;
  font-weight: 1000;
  font-family: 'Roboto Flex', sans-serif;
}

.days {
  font-size: 3rem;
}

.time {
  font-size: 2rem;
  display: flex;
}

@keyframes bgColor {
  12.5% {
    background-color: hsl(0, 60%, 50%);
  }
  25% {
    background-color: hsl(39, 60%, 50%);
  }
  37.5% {
    background-color: hsl(60, 60%, 50%);
  }
  50% {
    background-color: hsl(90, 60%, 50%);
  }
  62.5% {
    background-color: hsl(180, 60%, 50%);
  }
  75% {
    background-color: hsl(240, 60%, 50%);
  }
  87.5% {
    background-color: hsl(280, 60%, 50%);
  }
  0%, 100% {
    background-color: hsl(328, 60%, 50%);
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@keyframes spinBackwards {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}

@media only screen and (max-width: 600px) {
  .layerThree {
    height: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
  
  .layerTwo {
    height: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
  
  .layerOne {
    height: 160px;
    width: 160px;
    top: calc(50% - 80px);
    left: calc(50% - 80px);
  }
  
  .card {
    height: 100px;
    width: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    user-select: none;
  }
  
  .days {
    font-size: 1.2rem;
  }
  
  .time {
    font-size: 1rem;
  }
}